<template lang="pug">
  v-container
    v-row
      v-col(cols="12")
        v-toolbar(
          color="green darken-3"
          dark
          dense
        )
          span New Category
          v-spacer
          v-icon mdi-shape
        v-alert(
          class="mt-2"
          type="error"
          dismissible
          v-for="(item, index) in prdCatPostErrors"
          :key="index"
        )
          span.text-uppercase {{ item.first() }}
      v-col(cols="12")
        v-text-field(
          label="ID"
          outlined
          dense
          v-model="id"
        )
      v-col(cols="12")
        v-text-field(
          label="Name"
          outlined
          dense
          v-model="name"
        )
      v-col(
        cols="6"
      )
        v-btn(
          color="primary"
          @click="storePrdCat"
          dark
          :loading="prdCatPosting"
        )
          span Save
</template>
<script>
import prdCatRepository from '@/repositories/product-category.repository'
import { postVars, errorHandler } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const storeDelay = searchDelay()

const inputVars = () => ({
  id: null,
  name: null,
})

export default {
  name: 'ProductCategoryCreate',
  data () {
    return {
      ...inputVars(),
      ...postVars('prd-cat'),
    }
  },
  methods: {
    storePrdCat () {
      if (this.prdCatPosting) return
      this.prdCatPosting = true
      this.prdCatPostErrors = []
      storeDelay(() => {
        const data = this.getInputData()
        prdCatRepository.store(data)
          .then(() => { })
          .catch(e => { this.prdCatPostErrors = errorHandler(e) })
          .then(() => { this.prdCatPosting = false })
      })
    },
    getInputData () {
      return {
        id: this.id,
        name: this.name,
      }
    },
  },
}
</script>
